.info-item {
  margin-top: 24px;
  width: 250px;
}
.info-item .label-info {
    font-size: 14px;
    font-weight: 600;
}

.info-item .value-info {
    font-size: 16px;
    font-weight: 400;
}
